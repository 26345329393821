<template>
    <!-- 選擇通話對象的視窗 原本要做但是動彈不得先停工 會員管理,CRM通話 -->
    <vs-popup :title="$t('components.call.call')" style="z-index: 51001" :active.sync="popupActive" @close="close">
        <div class="px-2" style="max-height: 400px; margin-top: -25px">
            <!-- 主醫師 -->
            <div class="cd-form-group" v-if="queueParams && queueParams.did && queueParams.did != did">
                <vs-divider>主醫師</vs-divider>
                <div class="flex justify-between items-center" v-if="queueParams.did">
                    <div class="whitespace-no-wrap truncate w-32">
                        <span>{{ queueParams.dname }}</span>
                    </div>
                    <div class="flex items-center text-primary cursor-pointer hover:font-semibold whitespace-no-wrap" @click="call(queueParams.did, queueParams.dname)">
                        <feather-icon class="mx-1" icon="VideoIcon" svgClasses="w-5 h-5"></feather-icon>
                        <span>{{ $t('apptMgmt.call') }}</span>
                    </div>
                </div>
            </div>
            <!-- 掛號回撥對象 -->
            <div class="cd-form-group" v-if="queueParams && queueParams.proxy_call_did && queueParams.proxy_call_did != did">
                <vs-divider>掛號回撥對象</vs-divider>
                <div class="flex justify-between items-center" v-if="queueParams.proxy_call_did">
                    <div class="whitespace-no-wrap truncate w-32">
                        <span>{{ queueParams.proxyCallName }}</span>
                    </div>
                    <div class="flex items-center text-primary cursor-pointer hover:font-semibold whitespace-no-wrap" @click="call(queueParams.proxy_call_did, queueParams.proxyCallName)">
                        <feather-icon class="mx-1" icon="VideoIcon" svgClasses="w-5 h-5"></feather-icon>
                        <span>{{ $t('apptMgmt.call') }}</span>
                    </div>
                </div>
            </div>
            <!-- 掛號代理人 -->
            <div class="cd-form-group" v-if="queueParams && queueParams.proxy && queueParams.proxy != dmid">
                <vs-divider>掛號代理人</vs-divider>
                <div class="flex justify-between items-center" v-if="queueParams.proxy">
                    <div class="whitespace-no-wrap truncate w-32">
                        <span>{{ queueParams.proxyName }}</span>
                    </div>
                    <div class="flex items-center text-primary cursor-pointer hover:font-semibold whitespace-no-wrap" @click="call(queueParams.proxy, queueParams.proxyName)">
                        <feather-icon class="mx-1" icon="VideoIcon" svgClasses="w-5 h-5"></feather-icon>
                        <span>{{ $t('apptMgmt.call') }}</span>
                    </div>
                </div>
            </div>
            <!-- 民眾照護者 -->
            <!-- <div class="cd-form-group" v-if="pInfo && pInfo.careGiver">
                <vs-divider>民眾照護者</vs-divider>
                <div class="cd-form-group flex justify-between items-center">
                    <div class="whitespace-no-wrap truncate w-48">
                        <span>{{ pInfo.careGiver[0].relationship }}</span>
                    </div>
                    <div class="whitespace-no-wrap truncate w-full">
                        <span>{{ pInfo.careGiver[0].name }}</span>
                    </div>
                    <div class="flex items-center text-primary cursor-pointer hover:font-semibold whitespace-no-wrap" @click="call(0, pInfo.careGiver[0].p_mid)">
                        <feather-icon class="mx-1" icon="VideoIcon" svgClasses="w-5 h-5"></feather-icon>
                        <span>{{ $t('apptMgmt.call') }}</span>
                    </div>
                </div>
            </div> -->
            <!-- 民眾 -->
            <div class="cd-form-group" v-if="pInfo">
                <vs-divider>{{ $t('components.call.patient') }}</vs-divider>
                <div class="flex justify-between items-center">
                    <div class="whitespace-no-wrap truncate w-64">
                        <span class="mr-1 text-lg" style="color: #2d9b32" v-if="pInfo.token">●</span>
                        <span class="mr-1 text-lg" style="color: #626262" v-if="!pInfo.token">●</span>
                        <span>{{ pInfo.name }}</span>
                    </div>
                    <div class="flex items-center text-primary cursor-pointer hover:font-semibold whitespace-no-wrap" @click="callPatient()" v-if="pInfo.token">
                        <feather-icon class="mx-1" icon="VideoIcon" svgClasses="w-5 h-5"></feather-icon>
                        <span>{{ $t('components.call.call') }}</span>
                    </div>
                    <div class="flex items-center text-grey whitespace-no-wrap" v-else>
                        <feather-icon class="mx-1 invisible" icon="VideoIcon" svgClasses="w-5 h-5"></feather-icon>
                        <span>{{ $t('components.call.offline') }}</span>
                    </div>
                </div>
            </div>
            <!-- 所屬機構醫師 -->
            <div class="cd-form-group" v-if="unitDocList">
                <vs-divider>{{ $t('components.call.medicalStaff') }}</vs-divider>
                <div v-for="(data, key) in unitDocList" :key="key">
                    <div class="cd-form-group flex justify-between items-center">
                        <div class="whitespace-no-wrap truncate w-64">
                            <span class="mr-1 text-lg" style="color: #2d9b32" v-if="data.token">●</span>
                            <span class="mr-1 text-lg" style="color: #626262" v-if="!data.token">●</span>
                            <span>{{ data.unitName }}</span>
                        </div>
                        <div class="whitespace-no-wrap truncate w-full">
                            <span>{{ data.dname }}{{ data.dtitle }}</span>
                        </div>
                        <div class="flex items-center text-grey whitespace-no-wrap" v-if="!data.token">
                            <feather-icon class="mx-1 invisible" icon="VideoIcon" svgClasses="w-5 h-5"></feather-icon>
                            {{ $t('components.call.offline') }}
                        </div>
                        <!-- <div class="flex items-center text-primary cursor-pointer hover:font-semibold whitespace-no-wrap" @click="call(data.did, data.dname)" v-else-if="data.status == 1000"> -->
                        <!-- <div class="flex items-center text-primary cursor-pointer hover:font-semibold whitespace-no-wrap" @click="call_control(1, pInfo.mid, data.mid, data.did, data.dname)" v-else-if="data.status == 1000"> -->
                        <div class="flex items-center text-primary cursor-pointer hover:font-semibold whitespace-no-wrap" @click="callDoctor(data.mid)" v-else-if="data.status == 1000">
                            <feather-icon class="mx-1" icon="VideoIcon" svgClasses="w-5 h-5"></feather-icon>
                            <span>{{ $t('apptMgmt.call') }}</span>
                        </div>
                        <div class="flex items-center text-dark whitespace-no-wrap" v-else-if="data.status == 2000">
                            <feather-icon class="mx-1 invisible" icon="VideoIcon" svgClasses="w-5 h-5"></feather-icon>
                            {{ $t('components.call.busy') }}
                        </div>
                        <div class="flex items-center text-grey whitespace-no-wrap" v-else-if="data.status == 3000">
                            <feather-icon class="mx-1 invisible" icon="VideoIcon" svgClasses="w-5 h-5"></feather-icon>
                            {{ $t('components.call.leave') }}
                        </div>
                    </div>
                </div>
            </div>
            <!-- 醫師 待診1000/忙錄2000/暫離3000 -->
            <!-- <div v-if="contacts">
                <vs-divider>{{'('+onlineDoc+'/'+contactTotal+') '+$t('member.transfer')}}</vs-divider>
                <div class="cd-form-group flex justify-between items-center" v-for="(item, key) in contacts" :key="key">
                    <div class="whitespace-no-wrap truncate w-32">
                        <span>{{item.name}}</span>
                    </div>
                    <div class="whitespace-no-wrap truncate w-full px-4 text-left">
                        <span>{{item.di_name}}</span>
                    </div>
                    <div class="mr-2 flex items-center text-primary cursor-pointer hover:font-semibold whitespace-no-wrap" @click="call(item.did, item.name)" v-if="item.status == 1000">
                        <feather-icon class="mx-1" icon="VideoIcon" svgClasses="w-5 h-5"></feather-icon>
                        <span>{{ $t('apptMgmt.call') }}</span>
                    </div>
                    <div class="mr-2 flex items-center text-dark whitespace-no-wrap" v-if="item.status == 2000">
                        忙碌
                    </div>
                    <div class="mr-2 flex items-center text-grey whitespace-no-wrap" v-if="item.status == 3000">
                        暫離
                    </div>
                </div>
            </div> -->
        </div>
        <call-popup :callActive="callActive" :payload="callPayload"></call-popup>
    </vs-popup>
</template>
<script>
import { QueryDoctorListByPartner, getPatientInfoByPid, call_p2p, getCallType } from '@/api/user'
import callPopup from '@/components/callPopup'
export default {
    components: {
        callPopup,
    },
    props: {
        callPopupActive: {
            type: Boolean,
            default: false,
            required: true,
        },
        queueParams: {
            type: Object,
            default: null,
            required: false,
        },
        snParams: {
            type: Object,
            default: null,
            required: false,
        },
        pid: {
            type: String,
            default: '',
            required: false,
        },
    },
    data() {
        return {
            contacts: [],
            callActive: false,
            callPayload: {},
            pInfo: null,
            unitDocList: [],
        }
    },
    computed: {
        popupActive: {
            get() {
                return this.callPopupActive
            },
            set(v) {
                return v
            },
        },
        // 聯絡人在線數量
        onlineDoc() {
            var onlineTotal = 0
            _.find(this.contacts, function (o) {
                if (o.num == true) {
                    onlineTotal = onlineTotal + 1
                }
            })
            return onlineTotal
        },
        // 聯絡人總數量
        contactTotal() {
            return this.contacts.length
        },
        did() {
            return this.$store.getters.did
        },
        partnerid() {
            return this.$store.getters.partner
        },
        dmid() {
            return this.$store.state.mid
        },
        UUID() {
            return this.$store.state.uuid
        },
    },
    watch: {
        popupActive(n) {
            if (n === true) {
                this.getDocList()
                this.getPatientInfo()
            }
        },
    },
    methods: {
        // 關閉視窗
        close() {
            this.unitDocList = []
            this.$emit('closePopup')
        },
        // 取合作夥伴的醫事人員
        getDocList() {
            const payload = {
                did: this.did,
                partnerid: this.partnerid,
                query: '',
            }
            QueryDoctorListByPartner(payload).then((res) => {
                if (res.data.status == 'OK') {
                    this.contacts = _.cloneDeep(res.data.data)
                    this.contacts = _.sortBy(this.contacts, [
                        function (o) {
                            return o.sort == 1
                        },
                    ])
                    this.contacts = _.sortBy(this.contacts, [
                        function (o) {
                            return o.status == 2000
                        },
                    ])
                    this.contacts = _.sortBy(this.contacts, [
                        function (o) {
                            return o.status == 3000
                        },
                    ])
                }
            })
        },
        // 取民眾資料(民眾照護者＆所屬機構醫師)
        getPatientInfo() {
            if (!this.pid) return
            const payload = {
                spid: this.pid,
            }
            getPatientInfoByPid(payload).then((res) => {
                if (res.data.status == 'OK') {
                    var pa_id = this.partnerid
                    var temp = []
                    var did = this.did
                    this.pInfo = res.data.data
                    temp = _.filter(res.data.e_doc_list, function (o) {
                        return o.partner_id == pa_id
                    })
                    temp.forEach((e) => {
                        e.docList.forEach((item) => {
                            item.unitId = e.id
                            item.unitName = e.unit_name
                        })
                        this.unitDocList = _.union(this.unitDocList, e.docList)
                    })
                    this.unitDocList = _.filter(this.unitDocList, function (o) {
                        return o.did != did
                    })
                    this.unitDocList = _.sortBy(this.unitDocList, [
                        function (o) {
                            return o.status == 2000
                        },
                    ])
                    this.unitDocList = _.sortBy(this.unitDocList, [
                        function (o) {
                            return o.status == 3000
                        },
                    ])
                    this.unitDocList = _.sortBy(this.unitDocList, [
                        function (o) {
                            return !o.token
                        },
                    ])
                }
            })
        },
        call_control(is_doctor, pmid, dmid, did, dname) {
            getCallType(this.partnerid).then((res) => {
                if (res.data.is_p2p == true) {
                    this.call_p2p(is_doctor, pmid, dmid)
                } else {
                    this.call(did, dname)
                }
            })
        },
        // 通話
        call(sdid = null, dname = null) {
            this.callPayload.spid = this.pInfo.pid
            this.callPayload.pname = this.pInfo.name
            if (this.queueParams) this.callPayload.qid = this.queueParams.qid
            if (sdid) this.callPayload.sdid = sdid
            if (dname) this.callPayload.dname = dname
            console.log(sdid, dname)
            this.$emit('closePopup')
            this.callActive = true
        },
        // p2p通話 is_doctor 0=民眾 1=醫事人員
        call_p2p(is_doctor, pmid, dmid = 0) {
            let route = _.replace(_.lowerCase(_.split(this.$route.fullPath, '/', 2)[1]), ' ', '')
            var payload = {
                partner_id: this.partnerid,
                is_doctor: is_doctor,
                to_mid: pmid,
                src: route,
            }

            if (dmid > 0) {
                payload['doc_mid'] = dmid
                var mid = dmid
            } else {
                var mid = pmid
            }

            if (this.snParams) payload.sn_id = this.snParams.id
            call_p2p(payload).then((res) => {
                if (res.data.status == 'OK') {
                    // $('#p2p').show();
                    this.$store.commit('SET_CALL_ID', res.data.callId)
                    this.$store.commit('SET_LOCAL_ID', 'curdr_' + this.partnerid + '_' + this.dmid + '_1')
                    this.$store.commit('SET_REMOTE_ID', 'curdr_' + this.partnerid + '_' + mid + '_' + is_doctor)
                    this.$store.commit('SET_CALLING', true)
                    this.$store.commit('SET_ROOM_ID', res.data.room_Id)
                    remoteID = res.data.remote_Id
                    console.log('callTo.vue call_p2p function remoteID => ', remoteID)
                    this.$store.dispatch('updateDoctorStatus', 2000)
                    p2p_call(true, res.data.room_Id, res.data.callId, res.data.remote_Id, false, res.data.caller, true, this.UUID)
                    localStorage.setItem('p2p_queueid', res.data.queue_id)
                    localStorage.setItem('p2p_tomid', this.dmid)
                    let send_data = {
                        queue_id: res.data.queue_id,
                    }
                    p2pCallData = send_data
                    this.$emit('closePopup')
                    if (this.$router.currentRoute.fullPath != '/emr2/' + this.pid + '/' + res.data.queue_id) {
                        this.$router.push('/emr2/' + this.pid + '/' + res.data.queue_id)
                    }
                } else {
                    if (is_doctor == 0) {
                        this.$vs.notify({
                            color: 'danger',
                            title: 'ERROR',
                            text: '民眾未上線',
                        })
                    } else {
                        this.$vs.notify({
                            color: 'danger',
                            title: 'ERROR',
                            text: '醫師未上線',
                        })
                    }
                    console.log('call ERROR: ' + res.data.msg)
                }
            })
        },
        // 打給民眾
        callPatient() {
            const payload = { ring: 1, direction: 1 }
            payload.mid = this.pInfo.mid
            payload.is_doctor = 0
            this.$store.dispatch('call_p2p', payload)
        },
        // 打給醫師
        callDoctor(mid) {
            const payload = { ring: 1, direction: 1 }
            payload.mid = mid
            payload.is_doctor = 1
            this.$store.dispatch('call_p2p', payload)
        },
    },
}
</script>
